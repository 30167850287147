const state = {
  products: [],
  cartItems: [],
  totalPrice: 0,
};

const getters = {
  getCartItems: (state) => {
    return state.cartItems;
  },
  getTotalPrice: (state) => {
    return state.totalPrice;
  },
};

const mutations = {
  ADD_TO_CART(state, payload) {
    state.cartItems.unshift(payload);
  },
  SET_TOTAL_PRICE(state, payload) {
    state.totalPrice += payload;
  },
  REMOVE_PRODUCT(state, payload) {
    state.cartItems.splice(payload.idx, 1);
    state.totalPrice -= payload.price;
  },
};

const actions = {
  addToCart({ commit, state }, { payload, successCallback, errorCallback }) {
    const exisitingItem = state.cartItems.find(
      (item) => item.id === payload.id
    );
    console.log(exisitingItem);
    if (!exisitingItem) {
      commit("ADD_TO_CART", payload);
      commit("SET_TOTAL_PRICE", payload.price);
      successCallback();
    } else {
      errorCallback();
    }
  },
  removeProduct({ commit }, obj) {
    commit("REMOVE_PRODUCT", obj);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
