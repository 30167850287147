<template>
    <section class="w3l-portfolio-8 py-5">
        <div class="portfolio-main py-md-5 py-3">
            <div class="container">
                <div class="header-section text-center mx-auto">
                    <h6 class="sub-titlehny">{{ $t("View_projects") }}</h6>
                    <h3>{{ $t("Latest_Projects") }}</h3>
                </div>
                <div class="row galler-top mt-lg-5 mt-4">
                    <div class="col-md-4 protfolio-item hover14">
                        <div class="mb-4">
                            <figure >
                                <img src="../assets/images/1/1-(26).png" alt="product" class="img-fluid">
                            </figure>
                        </div>
                    </div>
                    <div class="col-md-4 protfolio-item hover14">
                        <div class="mb-4">
                            <figure >
                                <img src="../assets/images/2/2-(36).png" alt="product" class="img-fluid">
                            </figure>
                        </div>
                    </div>
                    <div class="col-md-4 protfolio-item hover14">
                        <div class="mb-4">
                            <figure >
                                <img src="../assets/images/3/3-(1).png" alt="product" class="img-fluid">
                            </figure>
                        </div>
                    </div>
                </div>
                <router-link data-aos="zoom-in" data-aos-duration="1500"
                    class=" btn-style btn-primary mt-sm-5 mt-4 mr-2 showMore" to="/products" style="padding: 15px 40px">
                    {{ $t('More_products') }}</router-link>
            </div>
        </div>
    </section>
</template>

<script>

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

export default {
    methods: {
        show() {
            this.$viewerApi({
                images: this.images,
            })
        },
    },
}
</script>


<style scoped>
.showMore {
    position: relative;
    left: 43%;
}

@media screen and (max-width: 1000px) {

    .showMore {
        position: relative;
        left: 23%;
    }
}
</style>