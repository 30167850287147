<template>
    <section class="w3l-stats py-lg-5 py-5" id="stats">
        <div class="gallery-inner container py-md-5 py-3">
            <div class="row stats-con" data-aos="zoom-in" data-aos-duration="1500">
                <div class="col-lg-3 col-6 stats_info counter_grid">
                    <div class="icon">
                        <img style="height: 100px; width: 100px;" src="../assets/images/europe.png" alt="">
                    </div>
                    <div class="iCountUp">
                        DACH + EU
                        <h4 class="countup">
                            {{ $t("Banner_item1") }}
                        </h4>
                    </div>
                </div>
                <div class="col-lg-3 col-6 stats_info counter_grid1">
                    <div class="icon">
                        <img style="height: 100px; width: 100px;" src="../assets/icons/warehouse.png" alt="">
                    </div>
                    <div class="iCountUp">
                        +2k
                        <h4 class="countup">
                            {{ $t("Banner_item2") }}
                        </h4>
                    </div>
                </div>
                <div class="col-lg-3 col-6 stats_info counter_grid mt-lg-0 mt-5">
                    <div class="icon">
                        <img style="height: 100px; width: 100px;" src="../assets/icons/target.png" alt="">
                    </div>
                    <div class="iCountUp">
                        24
                        <h4 class="countup">
                            {{ $t("Banner_item3") }}
                        </h4>
                    </div>
                </div>
                <div class="col-lg-3 col-6 stats_info counter_grid2 mt-lg-0 mt-5">
                    <div class="icon">
                        <img style="height: 100px; width: 100px;" src="../assets/icons/delivery.png" alt="">
                    </div>
                    <div class="iCountUp">

                        7-10 days
                        <h4 class="countup">
                            {{ $t("Banner_item4") }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ICountUp from 'vue-countup-v2';
export default {
    name: 'demo',
    components: {
        ICountUp
    },
    data() {
        return {
            stat1: {
                startVal: 0,
                endVal: 1200,
                decimals: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            },
            stat2: {
                startVal: 0,
                endVal: 2120,
                decimals: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            },
            stat3: {
                startVal: 0,
                endVal: 1012,
                decimals: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            },
            stat4: {
                startVal: 0,
                endVal: 60,
                decimals: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            }
        };
    },
    methods: {
        onReady11: function (instance, CountUp) {
            const that = this;
            instance.update(that.endVal + 100);
        },
        onReady12: function (instance, CountUp) {
            const that = this;
            instance.update(that.endVal + 100);
        },
        onReady13: function (instance, CountUp) {
            const that = this;
            instance.update(that.endVal + 100);
        },
        onReady14: function (instance, CountUp) {
            const that = this;
            instance.update(that.endVal + 100);
        }
    }
};
</script>

<style scoped>
.iCountUp {
    font-size: 1.7em;
    font-display: bold;
    margin: 0;
    color: #ffffff;
    text-align: center;
}

.countup {
    color: #ffffff;
}

.icon {
    margin: 0 0 20px 0;
    text-align: center;
}
</style>