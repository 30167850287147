<template>
  <div>
    <div class="inner-banner"></div>
    <section class="w3l-breadcrumb">
      <div class="container">
        <ul class="breadcrumbs-custom-path">
          <li>
            <a href="#url">{{ $t("Home") }}</a>
          </li>
          <li class="active">
            <img
              style="height: 20px; margin-left: 5px"
              src="../assets/icons/right-arrow.png"
              alt=""
            />{{ $t("Contact_us") }}
          </li>
        </ul>
      </div>
    </section>
    <section class="w3l-contact-11 py-5">
      <div class="title-content text-center">
        <h6 class="sub-titlehny">{{ $t("Contact") }}</h6>
        <h3>{{ $t("Get_in_touch") }}</h3>
      </div>
      <div
        class="form-41-mian section-gap mt-5"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div class="container">
          <div class="d-grid align-form-map">
            <div class="form-inner-cont">
              <form class="signin-form" ref="form" @submit.prevent="sendEmail">
                <div class="form-input">
                  <label for="w3lName">{{ $t("Name") }}</label>
                  <input
                    type="text"
                    name="user_name"
                    v-model.trim="$v.userForm.name.$model"
                    required
                    id="w3lName"
                    placeholder=""
                  />
                </div>
                <div class="form-input">
                  <label for="w3lSender">{{ $t("Email") }}</label>
                  <input
                    type="email"
                    name="user_email"
                    v-model.trim="$v.userForm.email.$model"
                    required
                    id="w3lSender"
                    placeholder=""
                  />
                </div>
                <div class="form-input">
                  <label for="w3lMessage">{{ $t("Message") }}</label>
                  <textarea
                    placeholder=""
                    name="message"
                    v-model.trim="$v.userForm.message.$model"
                    required
                    id="w3lMessage"
                  ></textarea>
                </div>
                <div class="form-submit text-right">
                  <button
                    type="submit"
                    value="Send"
                    class="btn-style btn-primary buttoni"
                  >
                    {{ $t("Submit_message") }}
                  </button>
                </div>
              </form>
            </div>
            <div class="contact-info mt-3 contact">
              <h4>Prishtinë {{ $t("Office") }}</h4>
              <p class="mb-3">
                <label>{{ $t("Address") }}: </label> Asr Laplje Selo, Prishtine
                Kosovo
              </p>
              <p class="mb-3">
                <label>{{ $t("Email") }} : </label>
                <a
                  href="mailto:contacts@digitbusiness.ch?subject=Contact from Woody"
                  class="email"
                  >contacts@woodsama.com</a
                >
              </p>
              <p class="mb-3">
                <label>{{ $t("Phone") }} : </label>
                <a
                  href="tel:+383 49
                                    999 433"
                >
                  +383 49 999 433</a
                >
              </p>
              <p class="mb-3">
                <label>{{ $t("Hours") }} : </label> {{ $t("Mon_Sat") }}: 8am –
                5pm
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="contant11-top-bg mt-lg-5 mt-4"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div class="container">
          <div class="d-grid contact pt-lg-4">
            <div
              class="contact-info-left d-grid text-left"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div class="map">
                <iframe
                  src="https://maps.google.com/maps?q=Asr%20Laplje%20Selo,%20Prishtine%20Kosovo&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      userForm: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitted: false,
    };
  },
  validations() {
    return {
      userForm: {
        name: { required }, // Matches this.firstName
        email: { required }, // Matches this.lastName
        message: { required }, // Matches this.contact.email
      },
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_59hbpy8",
          "template_rqiiv6t",
          this.$refs.form,
          "sBUO9jJU_Ds1ggfda"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );

      event.target.reset();
    },
  },
};
</script>

<style scoped>
.contact {
  height: 400px;
  width: 90%;
  position: relative;
  top: 5%;
}

.map {
  height: 500px;
  width: 1100px;
}

.contant11-top-bg {
  margin-bottom: 100px;
}

@media all and (max-width: 590px) {
  .map {
    height: 300px;
    width: 360px;
  }

  .contant11-top-bg {
    margin-bottom: 0;
  }

  .contact {
    height: 400px;
    width: 100%;
    position: relative;
    top: 5%;
  }

  .buttoni {
    width: 100%;
  }
}
</style>
