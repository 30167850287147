<template>
  <div style="margin-top: 15%; margin-bottom: 15%">
    <div class="container d-flex" style="justify-content: center">
      <form class="d-flex" ref="form" @submit.prevent="sendEmail">
        <div class="form">
          <div class="formBorder">
            <div class="form-row">
              <div class="col-md-6 mb-3 form-input">
                <label for="validationCustom01">{{ $t("First_name") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom01"
                  :placeholder="$t('First_name')"
                  required
                  name="name"
                  v-model="form.name"
                />
              </div>
              <div class="col-md-6 mb-3">
                <label for="validationCustom02">{{ $t("Last_name") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  :placeholder="$t('Last_name')"
                  required
                  name="lastName"
                  v-model="form.lastName"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationCustom03">{{ $t("Email") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom03"
                  :placeholder="$t('Email')"
                  required
                  name="email"
                  v-model="form.email"
                />
              </div>
              <div class="col-md-6 mb-3">
                <label for="validationCustom04">{{ $t("Phone_number") }}</label>
                <input
                  type="number"
                  class="form-control"
                  id="validationCustom04"
                  :placeholder="$t('Phone_number')"
                  required
                  name="phone"
                  v-model="form.phoneNumber"
                />
              </div>
            </div>
            <button
              class="btn-primary"
              type="submit"
              value="Send"
              style="float: right; border-radius: 10px; padding: 5px 10px"
            >
              {{ $t("Submit") }}
            </button>
          </div>
        </div>
        <div>
          <div v-show="false" v-for="item in getCartItems" :key="item.id">
            <b-form-group>
              <b-form-input name="name_of_product" v-model="item.name" />
            </b-form-group>
            <b-form-group>
              <b-form-input name="description" v-model="item.description" />
            </b-form-group>
            <b-form-group>
              <b-form-input name="price" v-model="item.price" />
            </b-form-group>
          </div>
          <div style="">
            <div
              v-for="(item, index) in getCartItems"
              :key="index"
              style="
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid gray;
              "
            >
              <img
                v-viewer
                :src="item.img"
                style="height: 100px; width: 100px"
                alt=""
              />
              <div class="content">
                <h3 name="name_of_product" style="font-size: 1rem">
                  {{ item.name }}
                </h3>
                <p name="description">{{ item.description }}</p>
              </div>
              <p name="price" style="width: 120px" class="price">
                <b
                  >{{ item.price }}
                  <b-icon
                    font-scale="1"
                    icon="currency-euro"
                    aria-hidden="true"
                  >
                  </b-icon>
                </b>
              </p>
              <b-icon
                font-scale="1"
                class="iconn"
                @click="removeItem(index, item.price)"
                icon="x"
                aria-hidden="true"
              >
              </b-icon>
            </div>
          </div>
          <div>
            <p v-if="getCartItems.length > 0" style="float: right">
              {{ $t("Total_price") }}:
              <b>
                {{ getTotalPrice }}
                <b-icon font-scale="1" icon="currency-euro" aria-hidden="true">
                </b-icon>
              </b>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import emailjs from "@emailjs/browser";

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        lastName: "",
        phoneNumber: "",
        name_of_product: "",
        description: "",
        price: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["getCartItems", "getTotalPrice"]),
  },

  watch() {},
  methods: {
    ...mapActions(["removeProduct"]),
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    removeItem(index, price) {
      const obj = {
        idx: index,
        price: price,
      };
      this.removeProduct(obj);
    },
    sendEmail() {
      emailjs
        .sendForm(
          "service_59hbpy8",
          "template_thlhiu1",
          this.$refs.form,
          "sBUO9jJU_Ds1ggfda"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );

      event.target.reset();
      this.$router.push("/products");
    },
  },
};
</script>

<style scoped>
.totalPrice {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.iconn {
  cursor: pointer;
}

.bottomm {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.bottomm h4 {
  align-content: center;
  display: flex;
  align-items: center;
}

.inputs {
  display: flex;
  flex-direction: column;
}

input {
  border-radius: 3px;
  border: 1px solid gray;
  padding: 5px;
}

.containerr {
  margin-top: 10%;
  margin-bottom: 10%;
  max-width: 30%;
}

.price {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

form {
  margin-right: 100px;
}

.form {
  margin-right: 100px;
  /* border: 1px solid black; */
  padding: 40px;
}

.buttoni {
  padding: 5px 20px;
}

.formBorder {
  border: 2px solid gray;
  padding: 50px;
  border-radius: 10px;
}
@media only screen and (max-width: 1200px) {
  label {
    font-size: 10px;
  }
  input {
    /* font: normal 19px/29px; */
    font-size: 12px;
  }
  button {
    width: 100%;
  }
}
@media only screen and (max-width: 960px) {
  /* label {
    font-size: 10px;
  }
  input {
    font-size: 10px;
  } */
  form {
    display: flex;
    flex-direction: column;
  }
  .form {
    width: 100%;
  }

  .form-row {
    /* display: flex;
    flex-direction: column; */
  }
}

@media all and (max-width: 500px) {
  .containerr {
    padding-top: 25%;
    max-width: 100%;
  }

  .price {
    width: 105px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0px;
    margin-top: 100px;
  }

  .form {
    margin-right: 0px;
    /* border: 1px solid black; */
    padding: 40px;
    /* border-radius: 10px; */
  }

  button {
    width: 100%;
  }
}
</style>
