<template>
    <header id="site-header" class="fixed-top" style="background-color: #222222;">
        <div class="container">
            <div>
                <b-navbar toggleable="lg" type="dark" variant="">
                    <!-- <b-navbar-brand to="/" class="navbar-brand" style="margin-right:20%"><span
                            class="sub-log">Wo</span>ody</b-navbar-brand> -->
                    <router-link to="/" class="woody">
                        <img class="woodyy" src="../assets/images/MicrosoftTeams-image (17).png" alt="">
                    </router-link>
                    <b-navbar-toggle target="nav-collapse">
                        <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
                        <span class="navbar-toggler-icon fa icon-close fa-times"></span>
                    </b-navbar-toggle>
                    <b-collapse id="nav-collapse" is-nav>
                        <b-navbar-nav>
                            <b-nav-item to="/" exact>{{ $t('Home') }} </b-nav-item>
                            <b-nav-item to="/about">{{ $t('About') }}</b-nav-item>
                            <b-nav-item to="/products">{{ $t('Products') }}</b-nav-item>
                            <b-nav-item to="/contact">{{ $t('Contact') }}</b-nav-item>
                        </b-navbar-nav>
                        <b-navbar-nav class="ml-auto">
                            <div style="display: flex; justify-content: space-around;margin-left: 35px ">
                                <h5 style="color: white; margin-right: 5px; align-self: center">DE</h5>
                                <label collapse navbar-collapse class="switch" style="align-self: center">
                                    <input type="checkbox" @click="toggle"
                                        :checked="getLanguage === 'de' ? false : true">
                                    <span class="slider round" style="margin-top: 11px"></span>
                                </label>
                                <h5 style="color: white; align-self: center; margin-right: 30px; ">EN</h5>
                                <div class="cartButton">
                                    <div>
                                        <b-icon class="cartIcon" v-b-modal.modal-cart font-scale="2" icon="cart"
                                            aria-hidden="true">
                                        </b-icon>
                                        <span class="counter" v-if="getCartItems.length > 0">{{ getCartItems.length
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </div>
        </div>
        <CartModal />
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CartModal from './modals/cartModal.vue'
export default {
    components: { CartModal },
    data() {
        return {
            lang: 'en',
            isActive: false,
        };
    },
    computed: {
        ...mapGetters(['getLanguage', 'getCartItems']),
        language() {
            if (this.getLanguage === 'en') {
                return 'Anglisht'
            } else if (this.getLanguage === 'de') {
                return 'Deutsch'
            }
            return 'Shqip'
        },

    },
    methods: {
        ...mapActions(['changeLanguage']),

        changeL(a) {
            this.changeLanguage(a)
            if (this.$i18n.locale !== a) {
                this.$i18n.locale = a
            }
        },
        toggle() {
            if (!this.isActive) {
                this.changeL("en")
                this.isActive = true;
            } else {
                this.changeL("de")
                this.isActive = false;

            }
        },
    },
}
</script>

<style scoped>
.navbar {
    display: flex;
    justify-content: space-around;
}

.woody {
    padding-right: 20%;
    height: 70px;
}

.woodyy {
    padding-right: 20%;
    height: 70px;
}

.router-link-active {
    color: #e44901 !important;
}

.cartButton {
    position: relative;

}

.counter {
    position: absolute;
    top: -2px;
    right: -6px;
    color: white;
    border: 1px solid white;
    background: #fe5a0e;
    border-radius: 50%;
    width: 20px;
    font-size: 13px;
    text-align: center;

}

.cartIcon {
    cursor: pointer;
    color: white;
}

.cartIcon:focus {
    outline: none;
}

.button1 {
    background-color: #222222;
    color: white;

}

.button1:hover {
    background-color: #e44901;

}

.button2 {
    background-color: #222222;
    color: white;

}

.button2:hover {
    background-color: #e44901;

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 35px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e44901;
    -webkit-transition: .4s;
    transition: .4s;
    height: 22.5px;
    width: 50px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    height: 15px;
    width: 15px;
    align-content: center;
}

input:checked+.slider {
    background-color: #e44901;
}

input:focus+.slider {
    box-shadow: 0 0 1px #e44901;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

#site-header {
    z-index: 2 !important;
}


@media screen and (max-width: 500px) {

    .woody {
        height: 100px;
        width: 180px;
    }
}
</style>


