<template>
    <b-modal id="modal-cart" centered :title="$t('Items_in_cart')" hide-footer ref="modal">

        <div v-for="(item, index) in getCartItems" :key="index" class="mapping">
            <img :src=item.img style="height: 100px; width: 100px" alt="">
            <div class="content">
                <h3 style="font-size: 1rem">{{ item.name }}</h3>
                <p>{{ item.description }}</p>
            </div>
            <p class="price">{{ $t("Price") }}:<b>{{ item.price }}<b-icon font-scale="1" icon="currency-euro"
                        aria-hidden="true">
                    </b-icon> </b></p>
            <b-icon font-scale="1" class="iconn"
                @click="removeItem(index, item.price), toast('b-toaster-top-right', true, 'danger', item)" icon="x"
                aria-hidden="true">
            </b-icon>
        </div>
        <hr style="color: 1px solid black">
        <div class="bottomModal">
            <div class="buttonContent">
                <router-link style="padding: 10px 10px !important;" to="/checkout" class="btn-primary buttoni"
                    @click.native="closeModal">{{ $t("Check_Out") }}
                </router-link>
            </div>
            <div class="totalPrice">
                <p>{{ $t("Total_price") }}: <b> {{ getTotalPrice }}</b>
                    <b-icon font-scale="1" icon="currency-euro" aria-hidden="true">
                    </b-icon>
                </p>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            totalPrice: 0,
            counter: 0
        }
    },
    computed: {
        ...mapGetters(["getCartItems", "getTotalPrice"]),

    },

    methods: {
        ...mapActions(["removeProduct"]),
        closeModal() {
            this.$refs.modal.hide();
        },
        removeItem(index, price) {
            console.log(index)
            const obj = {
                idx: index,
                price: price
            }
            this.removeProduct(obj)
        },
        toast(toaster, append = false, variant = null, item) {
            this.counter++
            this.$bvToast.toast(`${this.$t('item')}  ${this.$t('In_your_cart')}`, {
                title: `${item.name} ${this.$t('is_removed_from_your_card_list')} `,
                toaster: toaster,
                variant: variant,
                solid: true,
                appendToast: append
            })
        }
    },
}
</script>

<style scoped>
.bottomModal {
    display: flex;
    justify-content: space-between;
}

.content {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    text-align: center;
}

.iconn {
    cursor: pointer;
}

.buttoni {
    border-radius: 10px;
    padding: 5px 10px;
}

.t {
    margin-right: 5px
}

.mapping {
    display: flex;
    justify-content: space-evenly;
}
</style>