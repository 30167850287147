import photo1 from "../assets/images/1/1-(14).png";
import photo2 from "../assets/images/1/1-(26).png";
import photo3 from "../assets/images/1/1-(44).png";
import photo4 from "../assets/images/1/1-(52).png";
import photo5 from "../assets/images/1/1-(74).png";
import photo6 from "../assets/images/1/1-(81).png";
import RedMaple from "../assets/images/1/Red_Maple.png";

import photo7 from "../assets/images/2/2-(19).png";
import photo8 from "../assets/images/2/2-(27).png";
import photo9 from "../assets/images/2/2-(36).png";
import photo10 from "../assets/images/2/2-(53).png";
import photo11 from "../assets/images/2/2-(64).png";
import photo12 from "../assets/images/2/2-(74).png";
import WhiskeyOak from "../assets/images/2/Whiskey_Oak.png";

import photo13 from "../assets/images/3/3-(1).png";
import photo14 from "../assets/images/3/3-(25).png";
import photo15 from "../assets/images/3/3-(35).png";
import photo16 from "../assets/images/3/3-(47).png";
import photo17 from "../assets/images/3/3-(50).png";
import photo18 from "../assets/images/3/3-(57).png";
import BrownCloud from "../assets/images/3/Brown_Cloud.png";

import photo19 from "../assets/images/4/4-(51).png";
import photo20 from "../assets/images/4/4-(55).png";
import photo21 from "../assets/images/4/4-(70).png";
import photo22 from "../assets/images/4/4-(88).png";
import photo23 from "../assets/images/4/4-(95).png";
import photo24 from "../assets/images/4/4-(97).png";
import DarkSilk from "../assets/images/4/Dark_Silk.png";

import photo25 from "../assets/images/5/5-(3).png";
import photo26 from "../assets/images/5/5-(21).png";
import photo27 from "../assets/images/5/5-(25).png";
import photo28 from "../assets/images/5/5-(34).png";
import photo29 from "../assets/images/5/5-(61).png";
import photo30 from "../assets/images/5/5-(79).png";

import photo31 from "../assets/images/6/6-(5).png";
import photo32 from "../assets/images/6/6-(13).png";
import photo33 from "../assets/images/6/6-(22).png";
import photo34 from "../assets/images/6/6-(25).png";
import photo35 from "../assets/images/6/6-(26).png";
import SamuraiSaman from "../assets/images/6/Samurai_Saman.png";

import photo36 from "../assets/images/7/7-(15).png";
import photo37 from "../assets/images/7/7-(25).png";
import photo38 from "../assets/images/7/7-(36).png";
import photo39 from "../assets/images/7/7-(42).png";
import photo40 from "../assets/images/7/7-(45).png";

import photo41 from "../assets/images/8/8-(4).png";
import photo42 from "../assets/images/8/8-(7).png";
import photo43 from "../assets/images/8/8-(12).png";
import photo44 from "../assets/images/8/8-(15).png";
import photo45 from "../assets/images/8/8-(42).png";
import photo46 from "../assets/images/8/8-(47).png";
import AgedGalaxy from "../assets/images/8/Aged_Galaxy.png";

import photo47 from "../assets/images/9/9-(44).png";
import photo48 from "../assets/images/9/9-(46).png";
import photo49 from "../assets/images/9/9-(50).png";
import photo50 from "../assets/images/9/9-(56).png";
import photo51 from "../assets/images/9/9-(72).png";
import BowTie from "../assets/images/9/Bow_Tie.png";

import photo52 from "../assets/images/10/10-(8).png";
import photo53 from "../assets/images/10/10-(26).png";
import photo54 from "../assets/images/10/10-(32).png";
import photo55 from "../assets/images/10/10-(40).png";
import photo56 from "../assets/images/10/10-(45).png";
import CurveElm from "../assets/images/10/Curve_Elm.png";

import photo57 from "../assets/images/11/11-(5).png";
import photo58 from "../assets/images/11/11-(15).png";
import photo59 from "../assets/images/11/11-(29).png";
import photo60 from "../assets/images/11/11-(37).png";
import photo61 from "../assets/images/11/11-(46).png";

import photo62 from "../assets/images/12/12-(5).png";
import photo63 from "../assets/images/12/12-(12).png";
import photo64 from "../assets/images/12/12-(16).png";
import photo65 from "../assets/images/12/12-(20).png";
import photo66 from "../assets/images/12/12-(37).png";
import SamaPla from "../assets/images/12/Sama_Pla.png";

const _products = [
  {
    id: "AA_0001",
    img: photo3,
    name: "Red Maple ",
    description: "H: 189.7cm; W: 98.75cm; TH: 6cm; SQM: 1.8m2",
    // price: 340,
    // price: `340 €`,
    photos: [photo6, photo1, photo2, photo3, photo4, photo5, RedMaple],
  },
  {
    id: "AA_0002",
    img: photo10,
    name: "Whiskey Oak",
    description: "H: 196cm; W: 84.85cm; TH: 6.7cm; SQM: 1.6m2",
    // price: 530,
    photos: [photo12, photo8, photo9, photo10, photo11, photo7, WhiskeyOak],
  },
  {
    id: "AA_0003",
    img: photo13,
    name: "Brown Cloud",
    description: "H: 234.8 cm; W: 87cm; TH: 6.8 cm; SQM: 2.0m2",
    // price: 140,
    photos: [photo18, photo15, photo17, photo13, photo16, photo14, BrownCloud],
  },
  {
    id: "AA_0004",
    img: photo19,
    name: "Dark Silk",
    description: "H: 206.8cm; W: 80.75cm; TH: 6.5cm; SQM: 1.6m2",
    // price: 700,
    photos: [photo24, photo20, photo21, photo19, photo23, photo22, DarkSilk],
  },
  {
    id: "AA_0005",
    img: photo27,
    name: "Madonna",
    description: "",
    // price: 900,
    photos: [photo30, photo26, photo28, photo27, photo29, photo25],
  },
  {
    id: "AA_0006",
    img: photo31,
    name: "Samurai Saman",
    description: "H: 217cm; W: 78.7cm; TH: 6cm; SQM: 1.7m2",
    // price: 850,
    photos: [photo35, photo32, photo34, photo31, photo33, SamuraiSaman],
  },
  {
    id: "AA_0007",
    img: photo38,
    name: "Wood Bottle",
    description: "",
    // price: 960,
    photos: [photo40, photo39, photo36, photo38, photo37],
  },
  {
    id: "AA_0008",
    img: photo41,
    name: "Aged Galaxy ",
    description: "H: 198cm; W: 89.25 cm; TH: 6.5cm; SQM: 1.7m2",
    // price: 650,
    photos: [photo46, photo43, photo44, photo42, photo45, photo41, AgedGalaxy],
  },
  {
    id: "AA_0009",
    img: photo48,
    name: "Bow Tie",
    description: "H: 199.6cm; W: 97.25 cm; TH: 6.7cm; SQM: 1.9m2",
    // price: 790,
    photos: [photo51, photo48, photo49, photo47, photo50, BowTie],
  },
  {
    id: "AA_0010",
    img: photo52,
    name: "Curve Elm",
    description: "H: 205cm; W: 89.25 cm; TH: 6.2cm; SQM: 1.8m2",
    // price: 699,
    photos: [photo56, photo54, photo53, photo52, photo55, CurveElm],
  },
  {
    id: "AA_0011",
    img: photo57,
    name: "Tiger Tooth",
    description: "",
    // price: 520,
    photos: [photo61, photo58, photo59, photo57, photo60],
  },
  {
    id: "AA_0012",
    img: photo62,
    name: "SamaPIa",
    description: "H: 224cm; W: 88.75 cm; TH: 6cm; SQM: 1.9m2",
    // price: 610,
    photos: [photo66, photo63, photo65, photo62, photo64, SamaPla],
  },
];

export default {
  getProducts(cb) {
    setTimeout(() => cb(_products), 100);
  },

  buyProducts(products, cb, errorCb) {
    setTimeout(() => {
      Math.random() > 0.5 || navigator.userAgent.indexOf("PhantomJs") > -1
        ? cb()
        : errorCb();
    }, 100);
  },
};
