<template>
  <section class="w3l-grids-3 py-5">
    <div class="inner-banner"></div>
    <section class="w3l-breadcrumb">
      <div class="container">
        <ul class="breadcrumbs-custom-path">
          <li>
            <a to="/">{{ $t("Home") }}</a>
          </li>
          <li class="active">
            <img
              style="height: 20px; margin-left: 5px"
              src="../assets/icons/right-arrow.png"
              alt=""
            />
            {{ $t("Services") }}
          </li>
        </ul>
      </div>
    </section>
    <div class="container py-md-5 py-3 test">
      <div
        v-for="(item, index) in products"
        :key="item.id"
        class="grid-block height hover14"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div class="d-block p-lg-0 p-3">
          <img
            :src="item.img"
            style="width: 300px; margin-bottom: 2.5rem"
            alt=""
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div style="display: flex; justify-content: space-between">
            <h5
              style="text-align: center; font-size: 1.1rem; font-weight: 300"
              class="my-3"
            >
              {{ item.name }}
            </h5>
            <p style="color: black; text-align: center; font-weight: 600">
              {{ item.price }}
            </p>
          </div>
          <hr />
        </div>
        <div class="buttons">
          <button class="btn-primary buttoni" v-b-toggle="`collapse-${index}`">
            {{ $t("Show_more") }}
          </button>
          <button
            class="btn-primary buttoni"
            variant="success"
            @click="addItem(item)"
          >
            <b-icon
              class="cartIcon"
              font-scale="2"
              icon="cart-plus"
              aria-hidden="true"
            >
            </b-icon>
          </button>
        </div>
        <b-collapse :id="`collapse-${index}`" class="">
          <b-card>
            <p
              style="
                color: black;
                text-align: center;
                width: 75%;
                font-size: 13px;
                padding-left: 20%;
              "
            >
              {{ item.description }}
            </p>
            <div>
              <div class="images" v-viewer>
                <img
                  v-for="src in item.photos"
                  :key="src"
                  :src="src"
                  style="height: 60px; width: 60px"
                />
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

import "viewerjs/dist/viewer.css";

import shop from "@/api/shop";

export default {
  data() {
    return {
      products: [],
      counter: 0,
    };
  },
  setup() {
    const toast = () => {
      createToast("Wow, easy");
    };
    return { toast };
  },
  computed: {},
  created() {
    shop.getProducts((products) => {
      this.products = products;
    });
  },
  watch() {},

  methods: {
    ...mapActions(["addToCart"]),
    show() {
      this.$viewerApi({
        images: this.images,
      });
    },

    notification() {
      this.$toasted.show("Hello");
    },
    addItem(item) {
      this.addToCart({
        payload: item,
        successCallback: () => {
          this.toast("b-toaster-top-right", true, "success", item.name);
        },
        errorCallback: () => {
          this.toastt("b-toaster-top-right", true, "danger", item.name);
        },
      });
    },
    toast(toaster, append = false, variant = null, item) {
      this.counter++;
      this.$bvToast.toast(
        `${this.$t("item")} ${this.counter} ${this.$t("In_your_cart")}`,
        {
          title: `${item} ${this.$t("Added_in_your_cart")} `,
          toaster: toaster,
          variant: variant,
          solid: true,
          appendToast: append,
        }
      );
    },
    toastt(toaster, append = false, variant = null, item) {
      this.counter++;
      this.$bvToast.toast(`${this.$t("Cant_add")} ${this.$t("In_your_cart")}`, {
        title: `${item} ${this.$t("cant_be_added_twice")}`,
        toaster: toaster,
        variant: variant,
        solid: true,
        appendToast: append,
      });
    },
  },
};
</script>

<style scoped>
.height {
  height: 100%;
}

.test {
  display: flex;
  flex-flow: wrap;
}

.cartIcon {
  outline: none;
}

.images {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 50px;
  grid-row-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.buttoni {
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 5px;
  outline: none;
  margin-bottom: 5px;
}
</style>
