import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import language from "./modules/language";
import shop from "./modules/shop";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    language,
    shop,
  },
  plugins: [
    createPersistedState({
      paths: ["language"],
      storage: window.localStorage,
    }),
  ],
});
